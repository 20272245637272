(function($) {

    $(document).ready(function() {
        $(document).on('closed.zf.reveal', '[data-reveal]', function () {
            var $iframe = $(this).find("iframe");

            if ($iframe !== undefined) {
                var url = $iframe.data("url");
            }

            if (url !== undefined) {
                $iframe.attr('src', url);
            }
        });

        $(document).on('open.zf.reveal', '[data-reveal]', function () {
            var $iframe = $(this).find("iframe");

            if ($iframe !== undefined) {
                var url = $iframe.data("url");
            }

            if (url !== undefined) {
                $iframe.attr('src', url + "&autoplay=1&showinfo=0&rel=0");
            }
        });
    });

})(jQuery);
